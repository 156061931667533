import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { isMobileDevice } from "@/utils/utils";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("../App.vue"),
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("../views/PC/Home/Home.vue"),
      },
      {
        path: "introduce",
        name: "集团介绍",
        component: () => import("../views/PC/Introduce/Introduce.vue"),
        redirect: "/introduce/groupMatrix",
        children: [
          {
            path: "groupMatrix",
            name: "集团矩阵",
            component: () => import("../views/PC/Introduce/GroupMatrix.vue"),
          },
          {
            path: "countryside",
            name: "归农村",
            component: () => import("../views/PC/Introduce/Countryside.vue"),
          },
          {
            path: "liyuan",
            name: "世外梨园",
            component: () => import("../views/PC/Introduce/Liyuan.vue"),
          },
          {
            path: "aloe",
            name: "万亩芦荟",
            component: () => import("../views/PC/Introduce/Aloe.vue"),
          },
          {
            path: "delivery",
            name: "仓储配送",
            component: () => import("../views/PC/Introduce/Delivery.vue"),
          },
        ],
      },
      {
        path: "guinong",
        name: "何谓归农",
        component: () => import("../views/PC/Guinong/index.vue"),
        redirect: "/guinong/brand",
        children: [
          {
            path: "brand",
            name: "品牌文化",
            component: () => import("../views/PC/Guinong/BrandCulture.vue"),
          },
          {
            path: "company",
            name: "归农文艺",
            component: () => import("../views/PC/Guinong/CompanyCulture.vue"),
          },
        ],
      },
      {
        path: "news",
        name: "新闻动态",
        component: () => import("../views/PC/News/index.vue"),
        redirect: "/news/latest",
        children: [
          {
            path: "latest",
            name: "最新动态",
            component: () => import("../views/PC/News/Latest.vue"),
          },
          {
            path: "media",
            name: "媒体报道",
            component: () => import("../views/PC/News/Media.vue"),
          },
          {
            path: "characterStory",
            name: "归蜜故事",
            component: () => import("../views/PC/News/CharacterStory.vue"),
          },
          {
            path: "publicWelfare",
            name: "公益贡献",
            component: () => import("../views/PC/News/PublicWelfare.vue"),
          },
        ],
      },
      {
        path: "community",
        name: "社群互动",
        component: () => import("../views/PC/Community/index.vue"),
      },
      {
        path: "farming",
        name: "自然农法",
        component: () => import("../views/PC/Farming/index.vue"),
      },
      {
        path: "recruitment",
        name: "人才招聘",
        component: () => import("../views/PC/Recruitment/index.vue"),
      },
      {
        path: "mallApp",
        name: "商城App",
        component: () => import("../views/PC/MallApp/index.vue"),
      },
      {
        path: "product",
        name: "product",
        component: () => import("../views/images/index.vue"),
      },
    ],
  },
];

const mobileRouters: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "首页",
    component: () => import("../AppMobile.vue"),
    redirect: "/home",
    children: [
      {
        path: "introduce",
        name: "集团矩阵",
        component: () => import("../views/Mobile/Introduce/index.vue"),
      },
      {
        path: "home",
        name: "归农",
        component: () => import("../views/Mobile/Home/index.vue"),
      },
      {
        path: "guinong",
        name: "品牌文化",
        component: () => import("../views/Mobile/Guinong/index.vue"),
      },
      {
        path: "community",
        name: "蕙芷香遇俱乐部",
        component: () => import("../views/Mobile/Community/index.vue"),
      },
      {
        path: "mallApp",
        name: "App",
        component: () => import("../views/Mobile/MallApp/index.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: isMobileDevice() ? mobileRouters : routes,
});

export default router;
